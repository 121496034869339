import {
  cilBook,
  cibTwitter,
  cibLinkedin,
  cibFlickr,
  cibTumblr,
  cibXing,
  cibGithub,
  cilHome,
  cibGoogle,
  cibStackoverflow,
  cibYoutube,
  cibDribbble,
  cibInstagram,
  cibPinterest,
  cibVk,
  cibYahoo,
  cibBehance,
  cibReddit,
  cibVimeo,
  cibCcMastercard,
  cibCcVisa,
  cilContact,
  cibCcStripe,
  cibCcPaypal,
  cibCcApplePay,
  cibCcAmex,
  cifUs,
  cifBr,
  cifIn,
  cifFr,
  cifEs,
  cifPl,
  cilArrowBottom,
  cilArrowRight,
  cilArrowTop,
  cilBan,
  cilBasket,
  cilBell,
  cilCalculator,
  cilCalendar,
  cilCloudDownload,
  cilChartPie,
  cilCheck,
  cilChevronBottom,
  cilChevronTop,
  cilCheckCircle,
  cilCode,
  cilCommentSquare,
  cilCursor,
  cilDrop,
  cilClipboard,
  cilDollar,
  cilEnvelopeClosed,
  cilEnvelopeOpen,
  cilEuro,
  cilGlobeAlt,
  cilGrid,
  cilFile,
  cilJustifyCenter,
  cilLaptop,
  cilLayers,
  cilLightbulb,
  cilList,
  cilLocationPin,
  cilLockLocked,
  cilMagnifyingGlass,
  cilMediaPlay,
  cilMenu,
  cilMoon,
  cilNotes,
  cilOptions,
  cilPencil,
  cilPeople,
  cilPuzzle,
  cilSettings,
  cilShieldAlt,
  cilSpeech,
  cilSpeedometer,
  cilStar,
  cilTask,
  cilUser,
  cilBuilding,
  cilUserFemale,
  cilUserFollow,
  cilXCircle,
  cilTags,
  cilPen,
  cilTrash,
  cilHeart,
  cilBorderStyle,
  cilBriefcase,
  cilDescription,
  cilBadge,
  cilInfo,
  cilNewspaper,
  cilFeaturedPlaylist,
  cilX,
  cilCart,
  cilBalanceScale,
  cilTruck,
  cilAudio
} from '@coreui/icons';

export const iconsSet = {
  cilArrowBottom,
  cilBorderStyle,
  cilDescription,
  cilInfo,
  cilNewspaper,
  cilX,
  cilHome,
  cilClipboard,
  cilBuilding,
  cilArrowRight,
  cilArrowTop,
  cilBriefcase,
  cilHeart,
  cilPen,
  cilTrash,
  cilBan,
  cilBasket,
  cilBell,
  cilBadge,
  cilCalculator,
  cilCalendar,
  cilCloudDownload,
  cilChartPie,
  cilCheck,
  cilChevronBottom,
  cilChevronTop,
  cilCheckCircle,
  cilCode,
  cilCommentSquare,
  cilCursor,
  cilDrop,
  cilDollar,
  cilEnvelopeClosed,
  cilEnvelopeOpen,
  cilEuro,
  cilGlobeAlt,
  cilGrid,
  cilFile,
  cilJustifyCenter,
  cilLaptop,
  cilLayers,
  cilLightbulb,
  cilFeaturedPlaylist,
  cilList,
  cilLocationPin,
  cilLockLocked,
  cilMagnifyingGlass,
  cilMediaPlay,
  cilMenu,
  cilMoon,
  cilNotes,
  cilOptions,
  cilPencil,
  cilPeople,
  cilPuzzle,
  cilSettings,
  cilShieldAlt,
  cilSpeech,
  cilSpeedometer,
  cilStar,
  cilTask,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilXCircle,
  cifUs,
  cifBr,
  cifIn,
  cifFr,
  cifEs,
  cifPl,
  cilBook,
  cibTwitter,
  cibLinkedin,
  cibFlickr,
  cibTumblr,
  cilContact,
  cibXing,
  cibGithub,
  cibGoogle,
  cibStackoverflow,
  cibYoutube,
  cibDribbble,
  cibInstagram,
  cibPinterest,
  cibVk,
  cibYahoo,
  cibBehance,
  cibReddit,
  cibVimeo,
  cibCcMastercard,
  cibCcVisa,
  cibCcStripe,
  cibCcPaypal,
  cibCcApplePay,
  cibCcAmex,
  cilTags,
  cilCart,
  cilBalanceScale,
  cilTruck,
  cilAudio
};
