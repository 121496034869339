<template>
  <CBreadcrumb class="d-md-down-none me-auto mb-0">
    <CBreadcrumbItem
      v-for="(item, index) in breadcrumbs"
      :key="item"
      :href="breadcrumbs[breadcrumbs.length - 1].name === item.name ? '' : `${index > 2 ? setPath(index) : item.path}`"
      @click="onClickBreadcrumb"
    >
      {{ item.name }}
    </CBreadcrumbItem>
  </CBreadcrumb>
</template>

<script>
import router from '@/router';
import {CBreadcrumb, CBreadcrumbItem} from "@coreui/vue";
import {useBreadcrumbStore} from "@/store/breadcrumb";
import {storeToRefs} from "pinia";
import {onMounted, ref} from "vue";

export default {
  name: 'AppBreadcrumb',
  components: {CBreadcrumb, CBreadcrumbItem},
  setup() {
    const breadcrumbStore = useBreadcrumbStore();
    const {breadcrumbs, prevBreadcrumbs} = storeToRefs(breadcrumbStore);

    const realBreadcrumbs = ref();

    const getBreadcrumbs = () => router.currentRoute.value.matched.map((route) => {
      return {
        active: route.path === router.currentRoute.value.fullPath,
        name: route.meta.title ?? route.name,
        path: `${route.path.includes(':') ? route.path.split(':')[route.path.split(':').length - 2].includes('Id') ? route.path.split(':')[route.path.split(':').length - 2].split('Id')[1] + route.props.default.id : route.path.split(':')[route.path.split(':').length - 2] + route.props.default.id : route.path}`,
        id: route.props.default?.id
      };
    });

    const setPath = (index) => {
      let res = breadcrumbs.value[2].path;
      for (let i = 3; i <= index; i++) {
        res += breadcrumbs.value[i].path;
      }

      return res;
    };

    const onClickBreadcrumb = () => {
      breadcrumbs.value = getBreadcrumbs();
    };

    router.beforeEach(() => {
      prevBreadcrumbs.value = [...breadcrumbs.value];
    });

    router.afterEach(() => {
      breadcrumbs.value = getBreadcrumbs();
    });

    onMounted(() => {
      if (!sessionStorage.getItem("breadcrumb")) {
        breadcrumbs.value = getBreadcrumbs();
      } else {
        realBreadcrumbs.value = getBreadcrumbs();

        if (breadcrumbs.value.length >= realBreadcrumbs.value.length) {
          breadcrumbs.value = breadcrumbs.value.slice(0, realBreadcrumbs.value.length);
        } else {
          breadcrumbs.value = prevBreadcrumbs.value.slice(0, realBreadcrumbs.value.length);

          if (prevBreadcrumbs.value.length < realBreadcrumbs.value.length) {
            breadcrumbs.value.push(realBreadcrumbs.value[Object.keys(realBreadcrumbs.value).pop()]);
          }
        }
      }
    });

    return {
      breadcrumbs,
      getBreadcrumbs,
      setPath,
      onClickBreadcrumb
    };
  },
};
</script>

<style lang="scss" scoped src="./appBreadcrumb.scss">
</style>
